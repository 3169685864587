import { getKcContext } from 'keycloakify/login';

import { SignInType, SignUpType } from '../../../../shared/api/oauth/oauth';

import { DEBUG } from './KcApp';

export type KcContextExtension =
  | {
      pageId: 'info.ftl';
      linkButtonName?: string;
    }
  | {
      pageId: 'login.ftl';
      signInType: SignInType;
    }
  | {
      pageId: 'register.ftl';
      signUpType: SignUpType;
      register: {
        formData: {
          [key: string]: string;
        };
      };
      preset: {
        [key: string]: string;
      };
    };
export const { kcContext } = getKcContext<KcContextExtension>({
  mockPageId: DEBUG ? 'login.ftl' : undefined,
  mockData: [
    {
      pageId: 'login.ftl',
      client: {
        baseUrl: 'http://localhost:3000',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
    },
  ],
});

export type KcContext = NonNullable<
  typeof kcContext & {
    client: {
      baseUrl?: string;
    };
  }
>;
