import { Button } from '@mui/material';
import React, { ReactNode, useContext, useState } from 'react';

import menuIcon from '../../../../assets/icons/icon_menu.svg';
import { ColoredIcon } from '../../../../common/components/Icon';
import { LogoTitle } from '../../../../common/components/LogoTitle';
import {
  LayoutHeaderContainer,
  LayoutHeaderContent,
  LayoutHeaderContentLeft,
  LayoutHeaderContentRight,
} from '../../../../features/Layout/components';
import {
  WindowSize,
  WindowSizeContext,
} from '../../../../hooks/windowSizeHook';

import { MenuDrawer } from './MenuDrawer';

export interface KeycloakLayoutHeaderProps {
  links?: ReactNode;
  menus?: ReactNode;
}
export const KeycloakLayoutHeader = ({
  links,
  menus,
}: KeycloakLayoutHeaderProps) => {
  const windowSize = useContext(WindowSizeContext);
  const isMobile = windowSize === WindowSize.MOBILE;

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <LayoutHeaderContainer $height={isMobile ? '56px' : '114px'}>
        <LayoutHeaderContent>
          <LayoutHeaderContentLeft>
            <LogoTitle />
          </LayoutHeaderContentLeft>
          {isMobile ? (
            <LayoutHeaderContentRight $gap="28px">
              <Button
                sx={{
                  padding: 0,
                  minWidth: '20px',
                }}
                onClick={() => setOpenMenu(true)}>
                <ColoredIcon
                  width="20px"
                  height="20px"
                  imageUrl={menuIcon}
                  imageWidth="20px"
                  color="white"
                />
              </Button>
            </LayoutHeaderContentRight>
          ) : (
            <LayoutHeaderContentRight $gap="34px">
              {links}
            </LayoutHeaderContentRight>
          )}
        </LayoutHeaderContent>
      </LayoutHeaderContainer>
      <MenuDrawer
        open={openMenu}
        onOpen={() => setOpenMenu(true)}
        onClose={() => setOpenMenu(false)}
        menus={menus}
      />
    </>
  );
};
