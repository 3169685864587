import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import facebookIcon from '../../assets/icons/icon_facebook.png';
import instagramIcon from '../../assets/icons/icon_instagram.png';
import youtubeIcon from '../../assets/icons/icon_youtube.png';
import { Spacer } from '../../common/components/Spacer';
import { WindowSize, WindowSizeContext } from '../../hooks/windowSizeHook';

import {
  CompanyName,
  Copyright,
  LayoutFooter as LayoutFooterWrapper,
  LayoutFooterAside,
  LayoutFooterContact,
  LayoutFooterContent,
  LayoutFooterCoperation,
  LayoutFooterMenu,
  LayoutFooterMenuContent,
  LayoutFooterMenuContentItem,
  LayoutFooterMenuHeading,
  LayoutFooterMenuLink,
  LayoutFooterMenuSection,
  LayoutFooterMenuSection2,
  LayoutFooterMenuSectionHidden,
  LayoutFooterMenuSnsIcons,
  LayoutFooterMenuText,
  SnsIcon,
  LayoutFooterMenuInternalLink,
  LayoutFooterBoldMenuLink,
} from './components';

export const LayoutFooter = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'layout.footer' });

  const windowSize = useContext(WindowSizeContext);
  const isMobile = windowSize === WindowSize.MOBILE;

  return (
    <LayoutFooterWrapper>
      <LayoutFooterAside>
        <LayoutFooterContent>
          <LayoutFooterMenu>
            <LayoutFooterMenuSection>
              <LayoutFooterMenuHeading>
                {t('menus.familyProduct')}
              </LayoutFooterMenuHeading>
              <LayoutFooterMenuContent>
                <LayoutFooterMenuContentItem>
                  <LayoutFooterMenuLink
                    href="https://www.healthhub.kr/"
                    target="_blank">
                    {t('menus.dicomlink')}
                  </LayoutFooterMenuLink>
                </LayoutFooterMenuContentItem>
                <LayoutFooterMenuContentItem>
                  <LayoutFooterMenuLink
                    href="https://www.hpacsweb.com/"
                    target="_blank">
                    {t('menus.hpacs')}
                  </LayoutFooterMenuLink>
                </LayoutFooterMenuContentItem>
                <LayoutFooterMenuContentItem>
                  <LayoutFooterMenuLink
                    href="https://www.boneage.io/"
                    target="_blank">
                    {t('menus.boneage')}
                  </LayoutFooterMenuLink>
                </LayoutFooterMenuContentItem>
                <LayoutFooterMenuContentItem>
                  <LayoutFooterMenuLink
                    href="http://hrefer.dicomlink.kr/"
                    target="_blank">
                    {t('menus.hRefer')}
                  </LayoutFooterMenuLink>
                </LayoutFooterMenuContentItem>
                <LayoutFooterMenuContentItem>
                  <LayoutFooterMenuLink
                    href="https://www.radnet.kr/"
                    target="_blank">
                    {t('menus.hTeleRad')}
                  </LayoutFooterMenuLink>
                </LayoutFooterMenuContentItem>
                <LayoutFooterMenuContentItem>
                  <LayoutFooterMenuLink
                    href="http://market.dicomlink.kr/"
                    target="_blank">
                    {t('menus.aiMarketPlace')}
                  </LayoutFooterMenuLink>
                </LayoutFooterMenuContentItem>
              </LayoutFooterMenuContent>
            </LayoutFooterMenuSection>
            <LayoutFooterMenuSection2>
              <LayoutFooterMenuSection>
                <LayoutFooterMenuHeading>
                  {t('menus.resources')}
                </LayoutFooterMenuHeading>
                <LayoutFooterMenuContent>
                  <LayoutFooterMenuContentItem>
                    <LayoutFooterMenuLink
                      href="https://drive.google.com/u/0/open?id=1YkAbCeZ8ZcsjV96gXX6knLMu-zNdrR1O"
                      target="_blank">
                      {t('menus.productInformation')}
                    </LayoutFooterMenuLink>
                  </LayoutFooterMenuContentItem>
                  <LayoutFooterMenuContentItem>
                    <LayoutFooterMenuLink
                      href="https://drive.google.com/drive/u/0/folders/1I93q2NeUu_SXpUbdGTZCwy6kNWHbC7AL"
                      target="_blank">
                      {t('menus.manual')}
                    </LayoutFooterMenuLink>
                  </LayoutFooterMenuContentItem>
                  {isMobile && (
                    <LayoutFooterMenuContentItem>
                      <LayoutFooterMenuInternalLink to="/faq">
                        {t('menus.faq')}
                      </LayoutFooterMenuInternalLink>
                    </LayoutFooterMenuContentItem>
                  )}
                  {/* <LayoutFooterMenuContentItem>
                    <LayoutFooterMenuLink>
                      {t('menus.app')}
                    </LayoutFooterMenuLink>
                  </LayoutFooterMenuContentItem> */}
                </LayoutFooterMenuContent>
              </LayoutFooterMenuSection>
              <LayoutFooterMenuSection>
                <LayoutFooterMenuHeading>
                  {t('menus.company')}
                </LayoutFooterMenuHeading>
                <LayoutFooterMenuContent>
                  <LayoutFooterMenuContentItem>
                    <LayoutFooterMenuLink
                      href="https://www.healthhub.kr/"
                      target="_blank">
                      {t('menus.healthhub')}
                    </LayoutFooterMenuLink>
                  </LayoutFooterMenuContentItem>
                  <LayoutFooterMenuContentItem>
                    <LayoutFooterMenuLink
                      href="/terms-v2/terms-of-service"
                      target="_blank">
                      {t('menus.termsOfService')}
                    </LayoutFooterMenuLink>
                  </LayoutFooterMenuContentItem>
                  <LayoutFooterMenuContentItem>
                    <LayoutFooterBoldMenuLink
                      href="/terms-v2/privacy-policy"
                      target="_blank">
                      {t('menus.privacyPolicy')}
                    </LayoutFooterBoldMenuLink>
                  </LayoutFooterMenuContentItem>
                  {isMobile && (
                    <LayoutFooterMenuContentItem>
                      <LayoutFooterMenuLink
                        href="https://healthhub.kr/AboutUs/ContactUs"
                        target="_blank">
                        {t('menus.requestIntroduction')}
                      </LayoutFooterMenuLink>
                    </LayoutFooterMenuContentItem>
                  )}
                </LayoutFooterMenuContent>
                <LayoutFooterMenuSectionHidden>
                  <Spacer height={24} />
                  <LayoutFooterMenuHeading>
                    {t('menus.center')}
                  </LayoutFooterMenuHeading>
                  <LayoutFooterMenuContent>
                    <LayoutFooterMenuContentItem>
                      <LayoutFooterMenuLink href="tel:1522-4147">
                        {t('menus.phone')}
                      </LayoutFooterMenuLink>
                    </LayoutFooterMenuContentItem>
                    <LayoutFooterMenuContentItem>
                      <LayoutFooterMenuLink href="mailto:hh-marketing@healthhub.kr">
                        {t('menus.email')}
                      </LayoutFooterMenuLink>
                    </LayoutFooterMenuContentItem>
                    <LayoutFooterMenuContentItem>
                      <LayoutFooterMenuLink
                        href="https://healthhub.kr/AboutUs/ContactUs"
                        target="_blank">
                        {t('menus.requestIntroduction')}
                      </LayoutFooterMenuLink>
                    </LayoutFooterMenuContentItem>
                    {!isMobile && (
                      <LayoutFooterMenuContentItem>
                        <LayoutFooterMenuInternalLink to="/faq">
                          {t('menus.faq')}
                        </LayoutFooterMenuInternalLink>
                      </LayoutFooterMenuContentItem>
                    )}
                  </LayoutFooterMenuContent>
                </LayoutFooterMenuSectionHidden>
              </LayoutFooterMenuSection>
            </LayoutFooterMenuSection2>
            <LayoutFooterMenuSection $maxWidth={260}>
              <LayoutFooterMenuHeading>
                {t('menus.getInTouch')}
              </LayoutFooterMenuHeading>
              <LayoutFooterMenuContent>
                <LayoutFooterMenuContentItem>
                  <LayoutFooterMenuText>
                    {t('menus.snsDescription')}
                  </LayoutFooterMenuText>
                </LayoutFooterMenuContentItem>
                <LayoutFooterMenuSnsIcons>
                  <LayoutFooterMenuLink
                    href="https://www.facebook.com/healthhub2.0/"
                    target="_blank">
                    <SnsIcon width="30px" height="30px" src={facebookIcon} />
                  </LayoutFooterMenuLink>
                  <LayoutFooterMenuLink
                    href="https://www.instagram.com/healthhub_ai/"
                    target="_blank">
                    <SnsIcon width="30px" height="30px" src={instagramIcon} />
                  </LayoutFooterMenuLink>
                  <LayoutFooterMenuLink
                    href="https://youtube.com/@user-sh5bh2tu5g"
                    target="_blank">
                    <SnsIcon width="30px" height="30px" src={youtubeIcon} />
                  </LayoutFooterMenuLink>
                </LayoutFooterMenuSnsIcons>
              </LayoutFooterMenuContent>
            </LayoutFooterMenuSection>
          </LayoutFooterMenu>
          <LayoutFooterCoperation>
            <CompanyName>{t('coperation.companyName')}</CompanyName>
            <Copyright>{t('coperation.copyright')}</Copyright>
          </LayoutFooterCoperation>
        </LayoutFooterContent>
      </LayoutFooterAside>
      <LayoutFooterContact>
        <LayoutFooterContent>
          <span>{t('contact.(주)헬스허브')}</span>
          <span>{t('contact.주소')}</span>
          <span>{t('contact.사업자등록번호')}</span>
          <span>{t('contact.대표전화')}</span>
          <span>{t('contact.웹사이트')}</span>
          <span>{t('contact.이메일')}</span>
        </LayoutFooterContent>
      </LayoutFooterContact>
    </LayoutFooterWrapper>
  );
};
