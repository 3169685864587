import { ResourceLanguage } from 'i18next';

import englishJson from './english.json';
import koreanJson from './korean.json';

/** @deprecated */
export const koreanTranslation: ResourceLanguage = {
  translation: {},
};

export const koreanWebTranslation: ResourceLanguage = {
  translation: koreanJson,
};

export const koreanKey = 'ko';

export const englishWebTranslation: ResourceLanguage = {
  translation: englishJson,
};

export const englishKey = 'en';
