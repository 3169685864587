import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { LOGIN_IFRAME } from '../../../shared/api/oauth/oauth';
import { HSCAN_FRONT_URL } from '../../../shared/env';
import { getSignInUrl } from '../../../shared/utils/oauth';

const LOGIN_SUCCESS = 'oauth-login-success';
const TRY_SILENT_LOGIN = 'oauth-try-slient-login';

// set session header if exists
axios.interceptors.response.use(
  response => response,
  // try silent login with 401
  error => {
    const { response } = error;
    if (response.status && response.status === 401) {
      window.postMessage({ type: TRY_SILENT_LOGIN }, HSCAN_FRONT_URL);
    }
    return Promise.reject(error);
  },
);

interface OAuthLoginSuccessMessage {
  type: typeof LOGIN_SUCCESS;
}
interface OAuthTrySilentLoginMessage {
  type: typeof TRY_SILENT_LOGIN;
}

const isLoginSuccessMessage = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any,
): message is OAuthLoginSuccessMessage => {
  const type = (message as OAuthLoginSuccessMessage).type;
  return type && type === LOGIN_SUCCESS;
};
const isTrySilentLoginMessage = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any,
): message is OAuthTrySilentLoginMessage => {
  const type = (message as OAuthTrySilentLoginMessage).type;
  return type && type === TRY_SILENT_LOGIN;
};

export const postLoginSuccessMessage = () => {
  if (isSilentLoginIframe()) {
    window.opener.postMessage(
      {
        type: LOGIN_SUCCESS,
      },
      HSCAN_FRONT_URL,
    );
  }
};

export const useSilentLogin = () => {
  const [onSilentLogin, setSilentLogin] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (onSilentLogin) {
      // open login page in iframe and try silent login
      window.open(
        getSignInUrl({ prompt: 'none', redirect: '/oauth-silent-callback' }),
        LOGIN_IFRAME,
      );
    } else {
      // cleanup iframe
      window.open('about:blank', LOGIN_IFRAME);
    }
  }, [onSilentLogin]);

  const listener = useCallback(
    (event: MessageEvent) => {
      if (event.origin !== HSCAN_FRONT_URL) {
        return;
      }
      if (isTrySilentLoginMessage(event.data)) {
        setSilentLogin(true);
      }
      if (isLoginSuccessMessage(event.data)) {
        queryClient.invalidateQueries('autoSignIn');
        setSilentLogin(false);
      }
    },
    [setSilentLogin, queryClient],
  );

  useEffect(() => {
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [listener]);

  return onSilentLogin;
};

export const redirectToLoginPage = (redirect?: string) => {
  location.href = getSignInUrl({ redirect });
};

export const isSilentLoginIframe = () => {
  return !!window.opener;
};
