import React, { createContext, useEffect, useRef, useState } from 'react';

import { LOGIN_IFRAME } from '../../shared/api/oauth/oauth';
import { useSignOut } from '../../shared/hooks/oauthHook';

import {
  useSilentLogin,
  isSilentLoginIframe,
  postLoginSuccessMessage,
  redirectToLoginPage,
} from './hooks/oauthHook';

interface OAuthContext {
  onSilentLogin: boolean;
}
export const OAuthContext = createContext<OAuthContext>({
  onSilentLogin: false,
});

export const OAuthProvider = (props: React.PropsWithChildren) => {
  const { children } = props;
  const onSilentLogin = useSilentLogin();
  const { mutate: signout } = useSignOut({
    onSuccess: () => {
      redirectToLoginPage(location.pathname);
    },
  });

  return (
    <OAuthContext.Provider value={{ onSilentLogin }}>
      {!isSilentLoginIframe() && (
        <SilentLogin onLoginRequired={() => signout()} />
      )}
      {children}
    </OAuthContext.Provider>
  );
};

// trying to acquire new session seamlessly if keycloak session is alive
interface SilentLoginProps {
  onLoginRequired: () => void;
}
const SilentLogin = (props: SilentLoginProps) => {
  const { onLoginRequired } = props;
  const ref = useRef<HTMLIFrameElement>(null);

  const onLoad = () => {
    if (!ref.current?.contentDocument?.location) {
      // when login required and server returns 401 error
      onLoginRequired();
    }
  };
  return <iframe hidden ref={ref} onLoad={onLoad} name={LOGIN_IFRAME} />;
};

export const OAuthSilentCallback = () => {
  useEffect(() => {
    postLoginSuccessMessage();
  });
  return <></>;
};
