import axios from 'axios';

import { HSCAN_BACKEND_URL } from '../../env';
import { Gender } from '../hscan/account';

export const LOGIN_IFRAME = 'login-iframe';

export type SignInType = 'username' | 'email';

export const requestCheckSession = () => {
  return axios.get(`${HSCAN_BACKEND_URL}/session`);
};

// login request from keycloak page
export type SignInRequest = FormActionRequest<{
  username: string;
  password: string;
}>;

export const requestSignOut = () => {
  return axios.post(`${HSCAN_BACKEND_URL}/signout`, '', {
    withCredentials: true,
  });
};

export enum SignUpType {
  PHONE = 'phone',
  EMAIL = 'email',
}

export type SignUpRequest = FormActionRequest<{
  username?: string;
  password: string;
  'password-confirm': string;
  email?: string;
  name?: string;
  /** yyyy-MM-dd */
  birthDate?: string;
  gender?: Gender;
  telecom?: string;
  impUid?: string;
}>;

export type ResetPasswordRequest = FormActionRequest<{
  'password-new': string;
  'password-confirm': string;
}>;

type FormParams = {
  [key: string]: string | undefined;
};
type FormActionRequest<T extends FormParams> = {
  url: string;
} & T;
export const requestFormAction = async <T extends FormParams>(
  req: FormActionRequest<T>,
): Promise<void> => {
  const { url, ...form } = req;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow',
    body: new URLSearchParams(
      Object.entries(form).filter(([_, v]) => v !== undefined),
    ),
  })
    .then(res => {
      if (res.redirected) {
        window.location.replace(res.url);
        return undefined;
      }
      return res.text();
    })
    .then(html => {
      if (html) {
        document.open();
        document.write(html);
        document.close();
      }
      return;
    });
};
