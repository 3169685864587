import { useCallback, useContext } from 'react';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import { OAuthContext } from '../../web/src/OAuthProvider';
import {
  ResetPasswordRequest,
  SignInRequest,
  SignUpRequest,
  SignUpType,
  requestFormAction,
  requestSignOut,
} from '../api/oauth/oauth';
import { HGATE_INCLUDED } from '../env';
import { StorageContext, StorageKey } from '../storageKey';
import { SignInRedirectRequest, getSignInUrl } from '../utils/oauth';

export const useSignIn = () => {
  const signIn = useCallback(async (req: SignInRequest) => {
    await requestFormAction(req);
  }, []);
  return useMutation(signIn);
};

export const useSignInRedirect = () => {
  const { onSilentLogin } = useContext(OAuthContext);

  const signInRedirect = useCallback(
    async (req: SignInRedirectRequest) => {
      if (onSilentLogin) {
        return;
      }
      if (!HGATE_INCLUDED) {
        if (req.register && req.signUpType === undefined) {
          req.signUpType = SignUpType.EMAIL;
        }
        if (!req.register && req.signInType === undefined) {
          req.signInType = 'email';
        }
      }
      location.href = getSignInUrl(req);
    },
    [onSilentLogin],
  );
  return signInRedirect;
};

export const useSignOut = (
  options?: Omit<UseMutationOptions<void, unknown, void>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();
  const storage = useContext(StorageContext);
  const signOut = useCallback(async () => {
    await requestSignOut();
    storage.removeItem(StorageKey.USER);
    queryClient.invalidateQueries('autoSignIn');
  }, [queryClient, storage]);
  return useMutation(signOut, options);
};

export const useSignUp = () => {
  const signUp = useCallback(async (req: SignUpRequest) => {
    await requestFormAction(req);
  }, []);
  return useMutation(signUp);
};

export const useResetPassword = () => {
  const resetPassword = useCallback(async (req: ResetPasswordRequest) => {
    await requestFormAction(req);
  }, []);
  return useMutation(resetPassword);
};
