import React from 'react';
import { Outlet } from 'react-router-dom';

import { LayoutFooter } from '../../../../features/Layout/LayoutFooter';
import { LayoutBackground } from '../../../../features/Layout/components';

import {
  KeycloakLayoutHeader,
  KeycloakLayoutHeaderProps,
} from './KeycloakLayoutHeader';

export const KeycloakLayout = ({
  ...headerProps
}: KeycloakLayoutHeaderProps) => {
  return (
    <LayoutBackground>
      <KeycloakLayoutHeader {...headerProps} />
      <Outlet />
      <LayoutFooter />
    </LayoutBackground>
  );
};
