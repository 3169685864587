import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useContext, useMemo, useState } from 'react';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from 'react-query';

import { ErrorResponse } from '../api/hscan';
import {
  AccountInformation,
  ChangePasswordBody,
  CheckUsernameResponse,
  KeycloakErrorResponseDetail,
  requestAccountInformation,
  changeName,
  changePassword,
  changePhoneNumber,
  requestCheckUsername,
  resetPasswordWithPhone,
  unregister,
  changeLocale,
  Locale,
  changeGender,
  changeBirthDate,
  Gender,
  ResetPasswordWithPhoneRequest,
  IssueResetPasswordWithEmailRequest,
  issueResetPasswordWithEmail,
} from '../api/hscan/account';
import { Person, requestUserInfo } from '../api/hscan/demographics';
import { requestCheckSession } from '../api/oauth/oauth';
import { SetUserContext, UserContext } from '../context/UserContext';
import { StorageKey, StorageContext } from '../storageKey';

import { useSignOut } from './oauthHook';

export const useUserState = (): [Person | undefined] => {
  const user = useContext(UserContext);
  return useMemo(() => [user], [user]);
};

export const useRefreshUserInfo = () => {
  const queryClient = useQueryClient();
  const storage = useContext(StorageContext);
  const [user] = useUserState();
  return useCallback(async () => {
    if (user) {
      const newUser = await requestUserInfo();
      storage.setItem(StorageKey.USER, JSON.stringify(newUser.data));
      queryClient.invalidateQueries('autoSignIn');
    }
  }, [user, storage, queryClient]);
};

export const useAutoSignIn = () => {
  const setUser = useContext(SetUserContext);
  const storage = useContext(StorageContext);

  const autoSignIn = useCallback(async () => {
    try {
      await requestCheckSession();
      const newUser = await requestUserInfo();
      storage.setItem(StorageKey.USER, JSON.stringify(newUser.data));
      setUser(newUser.data);
      return newUser.data;
    } catch {
      storage.removeItem(StorageKey.USER);
      setUser(undefined);
      return undefined;
    }
  }, [setUser, storage]);

  return useQuery('autoSignIn', autoSignIn);
};

export const useUnregister = (
  options?: Omit<
    UseMutationOptions<void, unknown, string, unknown>,
    'mutationFn'
  >,
) => {
  const { mutate: signOut } = useSignOut();

  const fetch = useCallback(
    async (password: string) => {
      await unregister(password);
      signOut();
    },
    [signOut],
  );
  return useMutation(fetch, options);
};

export const useChangePassword = (
  options?: Omit<
    UseMutationOptions<
      void,
      AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>,
      ChangePasswordBody,
      unknown
    >,
    'mutationFn'
  >,
) => {
  const [user] = useUserState();
  const reset = useCallback(
    async (params: ChangePasswordBody) => {
      if (user) {
        await changePassword(params);
      } else {
        // authentication failed
        throw new Error();
      }
    },
    [user],
  );
  return useMutation(reset, options);
};

export const useChangePhoneNumber = (
  options?: Omit<
    UseMutationOptions<
      void,
      AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>,
      string,
      unknown
    >,
    'mutationFn'
  >,
) => {
  const fetch = useCallback(async (impUid: string) => {
    await changePhoneNumber({ impUid });
  }, []);
  return useMutation(fetch, options);
};

export const useNotVerifiedChangePhoneNumber = (
  options?: Omit<
    UseMutationOptions<
      void,
      AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>,
      string,
      unknown
    >,
    'mutationFn'
  >,
) => {
  const fetch = useCallback(async (phone: string) => {
    await changePhoneNumber({ phone });
  }, []);
  return useMutation(fetch, options);
};

export const useChangeName = (
  options?: Omit<
    UseMutationOptions<
      void,
      AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>,
      string,
      unknown
    >,
    'mutationFn'
  >,
) => {
  const fetch = useCallback(async (impUid: string) => {
    await changeName({ impUid });
  }, []);
  return useMutation(fetch, options);
};

export const useNotVerifiedChangeName = (
  options?: Omit<
    UseMutationOptions<
      void,
      AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>,
      string,
      unknown
    >,
    'mutationFn'
  >,
) => {
  const fetch = useCallback(async (name: string) => {
    await changeName({ name });
  }, []);
  return useMutation(fetch, options);
};

export const useAccountInformation = (impUid: string) => {
  const fetch = () => requestAccountInformation({ impUid });
  return useQuery<AxiosResponse<AccountInformation>, AxiosError<ErrorResponse>>(
    ['account', impUid],
    fetch,
    { enabled: !!impUid.length },
  );
};

export const useResetPasswordWithPhone = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse,
      AxiosError<ErrorResponse>,
      ResetPasswordWithPhoneRequest,
      unknown
    >,
    'mutationFn'
  >,
) => {
  return useMutation(resetPasswordWithPhone, options);
};

export const useIssueResetPasswordWithEmail = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse,
      AxiosError<ErrorResponse>,
      IssueResetPasswordWithEmailRequest,
      unknown
    >,
    'mutationFn'
  >,
) => {
  return useMutation(issueResetPasswordWithEmail, options);
};

export interface CheckUserRequest {
  username: string;
  phone?: string;
  email?: string;
}
export const useCheckUser = (
  request: CheckUserRequest,
  options?: {
    enabled?: boolean;
    onSuccess?: (data: CheckUsernameResponse) => void;
  },
) => {
  const { username, phone, email } = request;
  const fetch = useCallback(() => {
    return requestCheckUsername(username, phone, email);
  }, [username, phone, email]);
  return useQuery(['requestCheckUsername', username], fetch, {
    enabled: options?.enabled,
    onSuccess: d => options?.onSuccess && options?.onSuccess(d.data),
  });
};

export const useRememberUsername = () => {
  const storage = useContext(StorageContext);
  const savedUsername = localStorage.getItem(StorageKey.REMEMBER_USERNAME);
  const [doRemember, setDoRemember] = useState(savedUsername !== null);
  const saveUsername = useCallback(
    (username: string) => {
      if (doRemember) {
        storage.setItem(StorageKey.REMEMBER_USERNAME, username);
      } else {
        storage.removeItem(StorageKey.REMEMBER_USERNAME);
      }
    },
    [storage, doRemember],
  );

  return { doRemember, setDoRemember, savedUsername, saveUsername };
};

export const useChangeLocale = (
  options?: Omit<
    UseMutationOptions<
      void,
      AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>,
      string,
      unknown
    >,
    'mutationFn'
  >,
) => {
  const fetch = useCallback(async (locale: Locale) => {
    await changeLocale({ locale });
  }, []);
  return useMutation(fetch, options);
};

export const useChangeGender = (
  options?: Omit<
    UseMutationOptions<
      void,
      AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>,
      string,
      unknown
    >,
    'mutationFn'
  >,
) => {
  const fetch = useCallback(async (gender: Gender) => {
    await changeGender({ gender });
  }, []);
  return useMutation(fetch, options);
};

export const useChangeBirthDate = (
  options?: Omit<
    UseMutationOptions<
      void,
      AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>,
      string,
      unknown
    >,
    'mutationFn'
  >,
) => {
  const fetch = useCallback(async (birthDate: string) => {
    await changeBirthDate({ birthDate });
  }, []);
  return useMutation(fetch, options);
};
